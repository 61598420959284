import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getDisputeAdjudicationDetailsRequest,
    getDisputeMoneyMovementDetailsRequest,
    getDisputeSupportingInfoDetailsRequest,
    getDisputeOfferDetailsRequest
} from "../../redux/actions";
import DisputeAccordion from './disputeAccordion';
import DisputeSummary from './disputeSummary';
import DisputeStages from './disputeStages';
import DisputeCurrent from './disputeCurrent';
import DisputeResolver from './disputeResolver';
import DisputeUnderReview from './disputeUnderReview';
import DisputeResponseTemplate from './disputeResponseTemplate';
import DisputeWon from './disputeWon';
import DisputeLost from './disputeLost';
import { getColorByStatus } from '../../utils/disputeUtils';
import { svgIcons } from '../../elements';

import styles from './style.module.scss';
import { Notifications } from '../notifications/notifications';
import ManageDispute from './manageDispute';

const DisputeDetail = () => {
    const dispatch = useDispatch();
    const { disputeId } = useParams();
    const [disputeState, setDisputeState] = useState("");
    const [disputeOutcome, setDisputeOutcome] = useState("");
    const [status, setStatus] = useState("");
    const [disputePaymentGateway, setDisputePaymentGateway] = useState("");
    const [clickedTab, setClickedTab] = useState<string>("supporting_evidence");
    const {
        // disputeAdjudicationDetails,
        // disputeMoneyMovementDetails,
        // disputeSupportingInfoDetails,
        // disputeOfferDetails,
        disputeDetails
    } = useSelector((state: any) => state?.disputeDetailsStore);


    useEffect(() => {
        if (disputeId) {
            const payload = disputeId;
            dispatch(getDisputeAdjudicationDetailsRequest(payload));
            dispatch(getDisputeMoneyMovementDetailsRequest(payload));
            dispatch(getDisputeSupportingInfoDetailsRequest(payload));
            dispatch(getDisputeOfferDetailsRequest(payload));
        }
    }, [disputeId, dispatch]);

    useEffect(() => {
        if (disputeDetails?.isSuccess && disputeDetails.data) {
            setDisputeState(disputeDetails.data.disputeState);
            setDisputeOutcome(disputeDetails.data.disputeOutcome);
            setStatus(disputeDetails.data.status);
            setDisputePaymentGateway(disputeDetails.data?.paymentProcessor);
        }
    }, [disputeDetails.data]);

    return (
        <div className={styles.DisputeDetailsWrapper}>
            <div className={styles.DisputeDetailsBody}>
                <Notifications.Activate />
                <div>
                    <div className={styles.disputeIdWrapper}>
                        <h3>{disputeDetails?.data?.id ? `Dispute ${disputeDetails?.data?.id}` : 'Dispute'}</h3>
                        <p style={{
                            backgroundColor: getColorByStatus(disputeDetails?.data?.status)?.backgroundColor,
                            color: getColorByStatus(disputeDetails?.data?.status)?.textColor
                        }}>
                            {disputeDetails?.data?.status?.split('_').join(' ')}
                        </p>
                    </div>

                    <p className={styles.disputeIdTimeline}>This is the timeline of your dispute with responses and updates</p>
                </div>

                <div className={styles.cardsWrapper}>
                    <div className={styles.main}>
                        <DisputeStages status={status} disputeState={disputeState} disputeOutcome={disputeOutcome} />

                        {disputeState === "OPEN" &&
                            status === "WAITING_FOR_SELLER_RESPONSE" &&
                            (
                                <>
                                    <div className={styles.dispueByBuyer}>
                                        <DisputeCurrent />
                                    </div>
                                    <div className={styles.disputeResolver}>
                                        <DisputeResolver />
                                    </div>
                                    <div className={styles.disputeResolver}>

                    <ManageDispute
                      {...{
                        clickedTab,
                        setClickedTab,
                      }}
                    />
                  </div>
                                </>
                            )}

                        {(status === "UNDER_PAYPAL_REVIEW"
                        || status === "UNDER_REVIEW"
                        || disputeState === "REQUIRED_OTHER_PARTY_ACTION") && (
                            <div className={styles.disputeReviewWrapper}>
                                <DisputeUnderReview setDisputeState={setDisputeState} />
                            </div>
                        )}

                        {disputeState === "response template" && (
                            <>
                                <div className={styles.dispueByBuyer}>
                                    <DisputeCurrent />
                                </div>
                                <div className={styles.disputeResolver}>
                                    <DisputeResponseTemplate setDisputeState={setDisputeState} />
                                </div>
                            </>
                        )}

                        {disputeState === "RESOLVED" && disputeOutcome === "RESOLVED_SELLER_FAVOUR" && (
                            <div className={styles.disputeReviewWrapper}>
                                <DisputeWon />
                            </div>
                        )}

                        {disputeState === "RESOLVED" && disputeOutcome === "RESOLVED_BUYER_FAVOUR" && (
                            <div className={styles.disputeReviewWrapper}>
                                <DisputeLost />
                            </div>
                        )}
                    </div>

                    <div className={styles.sideMain}>
                        <div className={styles.disputeSummaryWrapper}>
                            <div className={styles.disputeSummaryHead}>
                                <p>Dispute Summary</p>
                                {disputePaymentGateway &&
                                    disputePaymentGateway?.toLowerCase() === "paypal" &&
                                    <img src={svgIcons.PayPal_Icon_Text} alt='PayPal' />
                                }
                                {disputePaymentGateway &&
                                    disputePaymentGateway?.toLowerCase() === "stripe" &&
                                    <img src={svgIcons.Stripe_Icon_Text} alt='Stripe' />
                                }
                                {disputePaymentGateway &&
                                    disputePaymentGateway?.toLowerCase() === "braintree" &&
                                    <div className={styles.summaryLogo}>
                                        <img src={svgIcons.BrainTree} alt='BrainTree' />
                                        <p>Braintree</p>
                                    </div>
                                }
                                {disputePaymentGateway &&
                                    disputePaymentGateway?.toLowerCase() === "shopifypayments" &&
                                    <div className={styles.summaryLogo}>
                                        <img src={svgIcons.Lachiepor} alt='ShopifyPayments' />
                                        <p>Shopify Payments</p>
                                    </div>
                                }
                                {disputePaymentGateway &&
                                    disputePaymentGateway?.toLowerCase() === "klarna" &&
                                    <div className={styles.summaryLogo}>
                                        <img src={svgIcons.klarna} alt='klarna' />
                                        <p>Klarna</p>
                                    </div>
                                }
                            </div>
                            <div className={styles.disputeSummary}>
                                <DisputeSummary disputeState={disputeState} />
                            </div>
                        </div>
                        <div className={styles.disputeFeatures}>
                            <DisputeAccordion />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisputeDetail;

