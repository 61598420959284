import DisputeOpenedByBuyer from './disputeOpenedByBuyer';
import UnderReview from './underReview';
import DisputeResult from './disputeResult';

import styles from './style.module.scss';

interface DisputeStageProps {
    status: string,
    disputeState: string,
    disputeOutcome: string
}

const DisputeStages = ({ status, disputeState, disputeOutcome }: DisputeStageProps) => {
    return (
        <div>
            <div className={styles.stageWrapper}>
                <div className={
                    (
                        disputeState !== "RESOLVED" &&
                        disputeState !== "UNDER_PAYPAL_REVIEW" &&
                        disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&

                        disputeState !== "response template" &&
                        disputeState !== "dispute lost"
                    )
                        ? styles.stage_1
                        : styles.underReview
                }>
                    <DisputeOpenedByBuyer disputeState={disputeState} />
                </div>
                <div className={
                    (
                        disputeState === "UNDER_PAYPAL_REVIEW" ||
                        disputeState === "REQUIRED_OTHER_PARTY_ACTION" ||
                        disputeState === "response template" ||
                        disputeState === "RESOLVED" ||
                        disputeState === "dispute lost"
                    )
                        ? styles.underReview
                        : styles.stage_2
                }>
                    <UnderReview disputeState={disputeState} />
                </div>
                <div className={
                    disputeState === "RESOLVED" || disputeState === "dispute lost"
                        ? styles.underReview
                        : styles.stage_3
                }>
                    <DisputeResult disputeState={disputeState} disputeOutcome={disputeOutcome} />
                </div>
            </div>

        </div>
    )
}

export default DisputeStages;
